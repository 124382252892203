<template>
    <div>
        <el-dialog title="舱单详情" :visible.sync="centerDialogVisible" width="70%" center>
            <div class="the_content">
                <div class="the_title">基本信息
                    <i v-show="basicalInformation == true" class="el-icon-arrow-up"
                        @click="basicalInformation = false"></i>
                    <i v-show="basicalInformation == false" class="el-icon-arrow-down"
                        @click="basicalInformation = true"></i>
                </div>
                <div v-show="basicalInformation" style="margin-top:15px;">
                    <el-form :inline="true" :model="base" class="demo-form-inline" label-position="right"
                        label-width="120px">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="主提单号：">
                                    <el-input class="the_base" :value="base.billno">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="自编号：">
                                    <el-input class="the_base" :value="base.workno">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="船公司：">
                                    <el-input class="the_base" :value="base.scompany"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="货物类型：">
                                    <el-select :value="base.goodtype" class="the_base">
                                        <el-option v-for="item in goodsType" :key="item.value" :label="item.label"
                                            :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="付款方式：">
                                    <el-select class="the_base" :value="base.payment">
                                        <el-option v-for="item in payWay" :key="item.value" :label="item.label"
                                            :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="提单类型：">
                                    <el-select class="the_base" :value="base.billtype">
                                        <el-option v-for="item in billType" :key="item.value" :label="item.label"
                                            :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="提单份数：">
                                    <el-input class="the_base" :value="base.billsnum">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="付款地点：">
                                    <el-input class="the_base" :value="base.payarea">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="the_title">船舶信息
                    <i v-show="shipInformation == true" class="el-icon-arrow-up" @click="shipInformation = false"></i>
                    <i v-show="shipInformation == false" class="el-icon-arrow-down" @click="shipInformation = true"></i>
                </div>
                <div v-show="shipInformation" style="margin-top:15px;">
                    <el-form :inline="true" :model="base" class="demo-form-inline" label-position="right"
                        label-width="120px">
                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="船名：">
                                    <el-input class="the_base" :value="base.vesselname">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="航次：">
                                    <el-input class="the_base" :value="base.voyageno">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="船代：">
                                    <el-input class="the_base" :value="base.webtype"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="发货地：">
                                    <el-input class="the_base" :value="base.originname"></el-input>
                                </el-form-item>
                                <el-form-item label="卸货港：">
                                    <el-input class="the_base" :value="base.unloadport">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="目的港：">
                                    <el-input class="the_base" :value="base.destportname">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="the_title">发货人信息
                    <i v-show="sPeopleInformation == true" class="el-icon-arrow-up"
                        @click="sPeopleInformation = false"></i>
                    <i v-show="sPeopleInformation == false" class="el-icon-arrow-down"
                        @click="sPeopleInformation = true"></i>
                </div>
                <div v-show="sPeopleInformation" style="margin-top:15px;">
                    <el-form :inline="true" :model="rsnData" class="demo-form-inline" label-position="right"
                        label-width="150px">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="发货人名称：">
                                    <el-input style="width:20rem;" :value="rsnData.sname"></el-input>
                                </el-form-item>
                                <el-form-item label="发货人地址：">
                                    <el-input style="width:25rem;" :value="rsnData.saddress"></el-input>
                                </el-form-item>
                                <el-form-item label="发货人企业代码：">
                                    <el-input style="width:30rem;" :value="rsnData.sCompanycode"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="发货人国家代码：">
                                    <el-input class="the_base" :value="rsnData.thesCountryCode"></el-input>
                                </el-form-item>
                                <el-form-item label="发货人联系电话：">
                                    <el-input class="the_base" :value="rsnData.sPhone"></el-input>
                                </el-form-item>
                                <el-form-item label="发货人AEO代码：">
                                    <el-input class="the_base" :value="rsnData.saeo">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="the_title">收货人信息
                    <i v-show="rPeopleInformation == true" class="el-icon-arrow-up"
                        @click="rPeopleInformation = false"></i>
                    <i v-show="rPeopleInformation == false" class="el-icon-arrow-down"
                        @click="rPeopleInformation = true"></i>
                </div>
                <div v-show="rPeopleInformation" style="margin-top:15px;">
                    <el-form :inline="true" :model="rsnData" class="demo-form-inline" label-position="right"
                        label-width="125px">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="收货人名称：">
                                    <el-input :value="rsnData.rname"></el-input>
                                </el-form-item>
                                <el-form-item label="收货人地址：">
                                    <el-input :value="rsnData.raddress"></el-input>
                                </el-form-item>
                                <el-form-item label="收货人企业代码：">
                                    <el-input :value="rsnData.rCompanycode"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="收货人国家代码：">
                                    <el-input class="the_base" :value="rsnData.therCountryCode"></el-input>
                                </el-form-item>
                                <el-form-item label="收货人联系电话：">
                                    <el-input class="the_base" :value="rsnData.rPhone"></el-input>
                                </el-form-item>
                                <el-form-item label="实际联系人：">
                                    <el-input class="the_base" :value="rsnData.contactpeople">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="实际联系人电话：">
                                    <el-input class="the_base" :value="rsnData.contactphone">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="收货人AEO代码：">
                                    <el-input class="the_base" :value="rsnData.raeo">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="the_title">通知人信息
                    <i v-show="nPeopleInformation == true" class="el-icon-arrow-up"
                        @click="nPeopleInformation = false"></i>
                    <i v-show="nPeopleInformation == false" class="el-icon-arrow-down"
                        @click="nPeopleInformation = true"></i>
                </div>
                <div v-show="nPeopleInformation" style="margin-top:15px;">
                    <el-form :inline="true" :model="rsnData" class="demo-form-inline" label-position="right"
                        label-width="150px">
                        <el-row>
                            <el-col :span="10">
                                <el-form-item label="通知人名称：">
                                    <el-input style="width:20rem;" :value="rsnData.nname"></el-input>
                                </el-form-item>
                                <el-form-item label="通知人地址：">
                                    <el-input style="width:25rem;" :value="rsnData.naddress"></el-input>
                                </el-form-item>
                                <el-form-item label="通知人企业代码：">
                                    <el-input style="width:30rem;" :value="rsnData.nCompanycode"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="通知人国家代码：">
                                    <el-input class="the_base" :value="rsnData.thenCountryCode"></el-input>
                                </el-form-item>
                                <el-form-item label="通知人联系电话：">
                                    <el-input class="the_base" :value="rsnData.nPhone"></el-input>
                                </el-form-item>
                                <el-form-item label="通知人AEO代码：">
                                    <el-input class="the_base" :value="rsnData.naeo">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="the_title">货物明细</div>
                <div class="rsnButton">
                    <span style="float:right;">
                        <span>总件数：{{ AllPackage }} </span>
                        <span></span>
                        <span>总毛重：{{ AllWeight }} </span>
                        <span></span>
                        <span>总体积：{{ AllVolume }}</span>
                    </span>
                </div>
                <div class="the_table">
                    <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" max-height="300"
                        :header-cell-style="{
                            background: '#eef1f6',
                            color: '#606278',
                            'text-align': 'center'
                        }" :cell-style="{ 'text-align': 'center' }">
                        <el-table-column v-for="item in goodsTable" :key="item.prop" :prop="item.prop"
                            :label="item.label" :width="item.width">
                            <template slot-scope="scope">{{ scope.row[item.prop] }}</template>
                        </el-table-column>
                        <slot name="todo">
                            <div></div>
                        </slot>
                    </el-table>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { querySelectGoods } from "@/api/shanghaiManifest"
import {
    goodsType,
    payWay,
    billType
} from "@/data/manifestInformation";
import { mapGetters, mapMutations } from 'vuex';
export default {
    data() {
        return {
            tableData: [],
            centerDialogVisible: false,
            AllPackage: 0,
            AllWeight: 0,
            AllVolume: 0,
            goodsTable: this.RESETTABLE.goodsDetail,
            shipInformation: true, //船舶信息
            basicalInformation: true, //基本信息
            rPeopleInformation: true,
            nPeopleInformation: true,
            sPeopleInformation: true,
            goodsType,
            payWay,
            billType,
            base: { //基本信息
                billno: '111', //主提单号
                workno: '', //自编号
                scompany: '', //船公司
                goodtype: '', //货物类型
                payment: '', //付款方式
                billtype: '', //提单类型
                billsnum: '', //提单份数
                payarea: '', //付款地点
                vesselname: '', //船名
                voyageno: '', //航次
                webtype: '', //船代
                originname: '', //发货地
                unloadport: '', //卸货港
                destportname: '', //目的港
                shipsCompany: this.RESETTABLE.shipCompany,
            },
            rsnData: {
                sname: '',
                saddress: '',
                sCompanycode: '',
                sPhone: '',
                saeo: '',
                rname: '',
                raddress: '',
                rCompanycode: '',
                rPhone: '',
                raeo: '',
                nname: '',
                naddress: '',
                nCompanycode: '',
                nPhone: '',
                naeo: '',
                contactpeople: '',
                contactphone: '',
                therCountryCode: '',
                thesCountryCode: '',
                thenCountryCode: '',
            } //收发通信息
        }
    },
    computed: {
        ...mapGetters(["goodsuuid"]),
    },
    methods: {
        ...mapMutations('ship', ['editGoodsuuid']),
        init(list) {
            this.centerDialogVisible = true;
            console.log(list,'list');
            this.base = {...list},
            this.editGoodsuuid(list.uuid);
            list.sftlist.forEach((item) => {
                if (item.type == 0) {
                    this.rsnData.sname = item.name;
                    this.rsnData.saddress = item.address;
                    this.rsnData.sCompanycode = item.code;
                    this.rsnData.sPhone = item.phone;
                    this.rsnData.saeo = item.aeocode;
                    this.rsnData.thesCountryCode = item.country;
                } else if (item.type == 1) {
                    this.rsnData.rname = item.name;
                    this.rsnData.raddress = item.address;
                    this.rsnData.rCompanycode = item.code;
                    this.rsnData.rPhone = item.phone;
                    this.rsnData.raeo = item.aeocode;
                    this.rsnData.therCountryCode = item.country;
                    this.rsnData.contactpeople = item.contactname;
                    this.rsnData.contactphone = item.contactphone;
                } else if (item.type == 2) {
                    this.rsnData.nname = item.name;
                    this.rsnData.naddress = item.address;
                    this.rsnData.nCompanycode = item.code;
                    this.rsnData.nPhone = item.phone;
                    this.rsnData.naeo = item.aeocode;
                    this.rsnData.thenCountryCode = item.country;
                }
            });
            this.queryGoods();
        },
        queryGoods() { //遍历货物列表
            querySelectGoods({
                uuid: this.goodsuuid
            })
                .then((response) => {
                    this.tableData = response.data.data;
                    let weightDate = 0;
                    let volumeData = 0;
                    let packageData = 0;
                    this.tableData.forEach((item) => {
                        packageData += item.packno;
                        weightDate += item.weight;
                        volumeData += item.volume;
                    });
                    this.AllPackage = packageData;
                    this.AllWeight = weightDate;
                    this.AllVolume = volumeData;
                })
        },
    }
}
</script>

<style lang="stylus" scoped>
.the_content {
    clear: both;

    .the_title {
        margin-top: 1rem;
        line-height: 4rem;
        font-size: 16px;
        border-radius: 5px;
        text-align: center;
        background: #dcdcdc;
    }
}

.el-icon-arrow-down,
.el-icon-arrow-up {
    cursor: pointer;
}

.xx_span {
    color: red;
    font-weight: bold;
    font-size: 15px;
}

.the_input {
    display: inline-block;
    margin: 1rem 0;
}

.the_base {
    width: 25rem;
    margin-right: 2rem;
}
</style>
