<template>
  <div>
    <el-dialog
        title="确认删除以下VGM数据吗？"
        :visible.sync="deleteDialogVisible"
        width="30%"
        append-to-body
        center
    >
      <el-table :data="deleteData" style="width: 100%" stripe border
                :header-cell-style="{background: '#eef1f6',color: '#606278'}"
      >
        <el-table-column
            prop="billNo"
            label="提单号"
        ></el-table-column>
        <el-table-column
            prop="vessel"
            label="船名"
        ></el-table-column>
        <el-table-column prop="voyage" label="航次" width="120"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
                <el-button @click="deleteDialogVisible = false">
                    取 消
                </el-button>
                <el-button type="danger" @click="Delete">删 除</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import {onDelete} from "@/api/vgm.js";

export default {
  data() {
    return {
      deleteDialogVisible: false,
      deleteData: [],
    };
  },
  methods: {
    init(list) {
      this.deleteDialogVisible = true;
      this.deleteData = list;
    },
    Delete() {
      const data = this.deleteData.map((x) => {
        return x.id;
      })
      onDelete(data)
          .then((res) => {
            if (res.data.status) {
              this.$message.success("删除成功");
              this.$emit("refresh");
            } else {
              this.$message.error(res.data.message);
            }

          })
          .finally(() => {
            this.deleteDialogVisible = false;
          });
    },
  },
};
</script>

<style lang="stylus" scoped></style>
