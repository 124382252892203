<template>
    <div>
        <el-tooltip content="点击打开聊天室">
            <img
                :src="chatSvg"
                alt=""
                style="width: 20px; height: 20px; cursor: pointer"
                @click="handleOpenChat"
            />
        </el-tooltip>
        <ChatDialog v-model:visible="visible" ref="chatDialogRef" />
    </div>
</template>
<script>
import { openChatRoom } from "@/api/shanghaiManifest";
import { apiCallback } from "@/utils";
import ChatDialog from "@/components/common/ChatDialog.vue";
export default {
    name: "ShanghaiChatDialog",
    props: ["id"],
    data() {
        return {
            visible: false,
            chatSvg: require("@/assets/img/shanghaiManifest/chat.svg"),
        };
    },
    components:{
        ChatDialog
    },
    methods: {
        handleOpenChat() {
            openChatRoom(this.id).then((res) => {
                const openId=apiCallback(res);
                this.visible = true;
                this.$refs.chatDialogRef.jumpRoom(openId)
                
            });
        },
    },
};
</script>
