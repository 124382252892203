<template>
  <div class="the_table">
    <el-table ref="multipleTable" border stripe :data="tableData" tooltip-effect="dark"
              @selection-change="handleSelectionChange" :header-cell-style="{
            background: '#eef1f6',
            color: '#606278',
            'text-align':'center'}" :cell-style="{'text-align':'center'}">
      <slot name="first">
        <el-table-column fixed type="selection" width="55">
        </el-table-column>
      </slot>
      <el-table-column
          type="index"
          fixed
          width="50">
      </el-table-column>
      <el-table-column v-for="item in tableHeader" :key="item.prop" :prop="item.prop" :label="item.label" align="left"
                       :width="item.width?item.width:'auto'" :fixed="item.fixed">
        <template slot-scope="scope">
          <span v-if="item.prop === 'billNo'" :class="item.prop">{{ scope.row[item.prop] }}<span
              v-if="scope.row.createUserId!=loginUserId"
              style="color:black;margin-left: 10px">{{ `(${scope.row.createUserName})` }}</span>
          <img
              src="@/assets/icon/copy.svg"
              v-clipboard:copy="scope.row.billNo"
              v-clipboard:success="copySucc"
          />
          </span>
          <span v-else-if="item.prop==='remark'"
                :class="[item.prop,scope.row[item.prop]?'table_link_text_bold_black':'']"
                @click="!scope.row[item.prop]?addRemark(scope.row):''"
                class="table_link_text_bold"
          ><i @click="addRemark(scope.row)" v-if="scope.row[item.prop]" class="el-icon-edit"></i>
            {{ scope.row[item.prop] ? scope.row[item.prop] : '添加备注' }}</span>
            <template v-else-if="item.prop === 'ctnNoList'">
              <div v-for="(ctnNo, index) in scope.row.ctnNoList" :key="index" class="ctn-no-item">
                {{ ctnNo }}
              </div>
            </template>
            <template v-else-if="item.prop === 'vgmGrossWeightList'">
              <div v-for="(vgmGrossWeight, index) in scope.row.vgmGrossWeightList" :key="index" class="ctn-no-item">
                {{ vgmGrossWeight }}
              </div>
            </template>
          <span v-else :class="item.prop">{{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
      <slot name="todo">
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <div>
              <span class="update_span" v-if="[0,1,2].includes(type)" @click="operator('copy',scope.row)">复制</span>
              <span class="update_span" v-if="[0,1,2].includes(type)" @click="operator('edit',scope.row)">修改</span>
              <span class="delete_span" v-if="[0,1].includes(type)" @click="showDelete(scope.row)">删除</span>
            </div>
          </template>
        </el-table-column>
        <!--        <template v-if="alreadySend">-->
        <!--          <el-table-column fixed="right" label="操作" width="160">-->
        <!--            <template slot-scope="scope">-->
        <!--              <span class="look_span" @click="operator('look',scope.row)">查看</span>-->
        <!--              <span class="update_span" @click="operator('edit',scope.row)">修改</span>-->
        <!--              <span class="delete_span" @click="toReturnDelete(scope.row)">删除</span>-->
        <!--            </template>-->
        <!--          </el-table-column>-->
        <!--        </template>-->
      </slot>

    </el-table>
    <div class="pagination_div">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="sizes,prev, pager, next"
          :total="totalCount"
          :page-count="totalPage"
          :page-sizes="[10, 20, 30, 40, 50,100,200]"
          :page-size="pageSize"
          :current-page="currPage">
      </el-pagination>
    </div>
    <DeleteVGMDialog ref="DeleteVGMDialog" @refresh="refreshList"></DeleteVGMDialog>
    <!-- 弹窗, 修改备注 -->
    <remark-box-dialog
        :show-tab="showTab"
        v-if="remarkDialogVisible"
        ref="RemarkBoxDialog"
        @refreshDataList="refreshList"
    ></remark-box-dialog>
  </div>
</template>

<script>
import LookDetailDialog from "./LookDetailDialog";
import ShanghaiChatDialog from "./ShanghaiChatDialog.vue";
import DeleteVGMDialog from "@/views/VGM/components/DeleteVGMDialog.vue";
import RemarkBoxDialog from "@/components/ship/RemarkDialog.vue";

export default {
  components: {
    RemarkBoxDialog,
    LookDetailDialog,
    DeleteVGMDialog,
    ShanghaiChatDialog
  },
  props: {
    tableHeader: {
      //列表标题
      type: Array,
      default: () => [],
    },
    tableData: {
      //列表数据
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      default: 0, //0:草稿箱，1：待发送，2：已发送
    },
    currPage: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 10,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    totalCount: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      remarkDialogVisible:false,
      showTab:'VGM',
      deleteDialogVisible1: false,
      isDraft: false,
      isWaitSend: false,
      isAlreadySend: false,
      lookDetailDialog: false,
      waitSend: false,
      alreadySend: false,
      AllPackage: 0,
      AllWeight: 0,
      AllVolume: 0,
      DeletetableData: [], //删除的表格数据
      DeleteID: "",
      deleteDialogVisible: false,
      UpdateManifest: false,
      multipleSelection: [], //多选中的信息
      goodsData: [],
    };
  },
  computed: {},
  methods: {
    copySucc() {
      this.$message.success("复制成功");
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit('fromChild', this.multipleSelection);
    },
    operator(type, item) {//edit,delete,copy
      if (type === 'look') {
        this.lookDetailDialog = true;
        this.$nextTick(() => {
          this.$refs.LookDetailDialog.init(item);
        })
      }
      //如果是复制的话，id设置为空
      if (type === 'copy') { 
        item.copy = true;
        console.log('复制数据' + JSON.stringify(item));
      }
      this.$emit(type, item);
    },
    agentDelete(id) {

    },
    refreshList() {
      this.$emit('refresh')
    },
    showDelete(item) {
      //删除舱单
      const deleteData = [];
      deleteData.push(item);
      this.$nextTick(() => {
        this.$refs.DeleteVGMDialog.init(deleteData);
      })
    },
    lookit(item) {
      //查看详情
      this.lookDetailDialog = true;
      this.$nextTick(() => {
        this.$refs.LookDetailDialog.init(item);
      });
    },
    handleSizeChange(val) {
      this.$emit("pageSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("nextPage", val);
    },
    //添加备注
    addRemark(item = this.rows) {
      this.remarkDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.RemarkBoxDialog.init({
          id: item.id,
          blno: item.billNo,
          remark: item.remark
        });
      });
    },
  }

}

</script>

<style lang="stylus" scoped>
.update_span {
  color: blue;
  display: inline-block;
  margin-right: 1.5rem;
}

.delete_span {
  color: red;
}

.the_table {
  margin-top: 1rem;
}

.pagination_div {
  text-align: center;
  margin: 1rem;
}

.look_span,
.update_span,
.delete_span {
  cursor: pointer;
}

.look_span {
  color: green;
  display: inline-block;
  margin-right: 1.5rem;
}

.pagination_div {
  text-align: center;
  margin: 1rem;
}

.billno {
  font-weight: bold;
  color: var(--BLUE-3366FF);
}

.vesselname {
  font-weight: bold;
}

.total {
  text-align: center;
  display: block;
}
.table_link_text_bold {
  color: var(--BLUE-5493f4);
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}
.billNo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--BLUE-3366FF);

  img {
    cursor: pointer;
  }
}
.table_link_text_bold_black {
  color: var(--BLACK-333);
  cursor: text;
  user-select: auto;

  i {
    cursor: pointer;
    user-select: none;
  }
}

.success {
  color: var(--GREEN-1E9855);
  font-weight: bold
}

.el-icon-edit {
  margin-right: 0.2rem;
}

.ctn-no-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  margin-top :5px
}
</style>
