<template>
  <div>
    <div class="input_div">
      <div v-if="type===0">
        <el-button type="primary" size="medium" @click="openManifestDialog()">新建VGM</el-button>
        <el-button type="primary" plain :size="size" @click="exportDialogVisible = true">VGM表格导入</el-button>
      </div>
      <div style="display:flex;">
        <div v-if="type===2" style="margin-right: 10px">
          <el-date-picker
              v-model="dateRange"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="setDate"
              :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <el-select class="el_select"
                   v-model="search.showUserId"
                   placeholder="选择用户"
        >
          <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <el-select class="el_select"
                   v-model="search.shipCompany"
                   placeholder="请选择船公司"
                   clearable>
          <el-option
              v-for="item in shipsCompany"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <el-input class="inline-input "
                  v-model="search.billno"
                  @keyup.enter.native="go2Search"
                  placeholder="请输入提单号(必填)"
                  clearable
                  maxlength="30">
        </el-input>
        <el-input class="inline-input "
                  v-model="search.vesselname"
                  @keyup.enter.native="go2Search"
                  placeholder="请输入船名"
                  clearable>
        </el-input>
        <el-button icon="el-icon-search" @click="go2Search"></el-button>
      </div>

      <div>
        <el-button type="warning" size="medium" @click="sendVGM" v-if="type===1">发送</el-button>
        <el-button type="primary" size="medium" plain @click="exportVgmHistoryExcel('未发送')" v-if="[0,1].includes(type)">导出</el-button>
        <el-button type="primary" size="medium" plain @click="exportVgmHistoryExcel('已发送')" v-if="type===2">导出记录</el-button>
        <el-button type="danger" size="medium" plain style="margin-right:1rem;" @click="toDelete()">删除</el-button>
      </div>
    </div>
    <el-dialog title="VGM表格导入" :visible.sync="exportDialogVisible" width="30%" center>
      <div class="export_module">
        <p>可先下载模板按照格式填写后上传</p>
      </div>
      <el-upload style="margin:2rem auto;text-align:center;"
                 :file-list="fileList" :auto-upload="false" class="upload-demo" drag action=""
                 :on-change="fileUpload" multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toDownload()">下载模板</el-button>
      </span>
    </el-dialog>
    <DeleteManifestDialog ref="DeleteManifestDialog" v-if="deleteDialogVisible" @refresh="go2Search">
    </DeleteManifestDialog>
  </div>
</template>

<script>
import DeleteManifestDialog from "./DeleteVGMDialog.vue";
import {exportShipExcel, sendShMould} from "@/api/shanghaiManifest";
import moment from "moment";
import {sendHyManifestShHead} from "../../../api/shanghaiManifest";
import {getAllCompanyStaff} from "@/api";
import {onSend, exportVgmHistoryExcelReq, importExcelModule ,downloadExcelModule} from "@/api/vgm";

export default {
  name: "ManifestSearchBar",
  components: {DeleteManifestDialog},
  props: {
    multipleSelection: {//选中的信息
      type: Array,
      default: () => []
    },
    type: {
      type: Number,
      default: 0//0:草稿箱，1：待发送，2：已发送
    }
  },
  data() {
    return {
      shipsCompany: this.RESETTABLE.scompany,
      userList: [],//企业用户可以筛选查看其他同事的单子
      loginId: '',
      loginUserId: '',
      deleteDialogVisible: false,
      fileList: [],
      exportDialogVisible: false,//列表模板下载
      search: {
        showUserId: this.loginUserId,
        shipCompany: '',
        billNo: '', //搜索的提单号
        vessel: '', //搜索的船名
        sort: "updatetime_desc",
        beginTime: '',
        endTime: ''
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateRange: ''//日期时间范围
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loginId = this.$store.state.user.loginId;
      this.loginUserId = this.$store.state.user.userId;
      this.resetUserList();
      if (this.loginId != "personal") {
        this.getCompanyUsers();
      }
    },
    resetUserList() {
      this.userList = [];
      const user = {
        id: this.loginUserId,
        label: '只看自己',
        value: this.loginUserId,
      };
      this.userList.push(user);
      this.search.showUserId = this.loginUserId;

    },
    getCompanyUsers() {
      this.userList = [];
      getAllCompanyStaff({
        cname: this.loginId,
      }).then((res) => {
        if (res.data.data.users) {
          this.userList = res.data.data.users.map(item => {
            return {
              id: item.id,
              label: this.loginUserId === item.id ? '只看自己' : item.userName,
              value: item.id,
            }
          })
        }
        const item = {
          id: '',
          label: '全部',
          value: null,
        }
        this.userList.unshift(item);

      }).catch((err) => {
        console.error(err);
      });
    },
    go2Search() {
      this.$emit('search', this.search)
    },
    //下载舱单模板
    toDownload() {
      downloadExcelModule().then((res) => {
        if (res.status === 200) {
          this.saveFile(res.data, `Vgm数据导入模板.xlsx`)
        }
      })
    },
    //导出列表
    exportExcel() {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "warning",
          message: "只能选择一条舱单记录！"
        })
      } else if (this.multipleSelection.length === 1) {
        exportShipExcel(this.multipleSelection[0].id)
            .then((response) => {
              if (response.status === 200) {
                this.saveFile(response.data, `${this.multipleSelection[0].billno}${moment().format('YYYYMMDDHHmmss')}舱单.xlsx`)
              }
            })
      } else {
        this.$message({
          type: "warning",
          message: "请选择一条舱单记录！"
        })
      }
    },
    //导出Vgm发送记录列表
    exportVgmHistoryExcel(text) {
      if (this.multipleSelection.length > 0) {
        const ids = this.multipleSelection.map(item => item.id);
        this.exportLoading = true;
        exportVgmHistoryExcelReq(ids)
            .then((response) => {
              if (response.status === 200) {
                this.saveFile(response.data, text+`VGM列表.xlsx`)
              }
            }).finally(() => {
          this.exportLoading = false;
        })
      } else {
        this.$message({
          type: "warning",
          message: "请选择一条VGM记录！"
        })
      }
    },
    //保存文件
    saveFile(data, fileName) {
      let blob = new Blob([data], {
        type: "application/vnd.ms-excel;charset=utf-8"
      }); // res就是接口返回的文件流了
      let url = window.URL || window.webkitURL;
      let fileURL = url.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = fileURL;
      a.download = fileName;
      a.target = "_self";
      a.click();
      url.revokeObjectURL(fileURL);
    },
    openManifestDialog() { //打开舱单编辑或新建仓单
      this.$emit('edit')
    },
    //上传模板导入舱单
    fileUpload(file) {
      let filename = file.name
      let fileSuffix = filename.substr(filename.lastIndexOf('.') + 1);
      if (fileSuffix !== 'xlsx') {
        this.$message({
          type: "warning",
          message: "只允许上传xlsx文件！"
        })
        return false
      }
      importExcelModule({file: file.raw, id: this.$store.state.user.userId})
          .then((response) => {
            if (response.data.status) {
              this.$message({
                type: "success",
                message: "导入成功，数据已保存在草稿箱！"
              })
              this.exportDialogVisible = false;
              this.fileList = []
              this.$emit('search');
            } else {
              this.$message.error(response.data.message);
            }
          })
    },
    setDate(val) {
      console.log(val)
      this.search.beginTime = val[0] + ' 00:00:00'
      this.search.endTime = val[1] + ' 23:59:00'
    },
    //发送
    sendVGM() {
      if (this.multipleSelection.length > 0) {
        const data = [];
        this.multipleSelection.forEach((item) => {
          data.push(item.id);
        })
        onSend(data).then(response => {
          if (response.data.status) {
            this.$message({
              type: "success",
              message: "发送成功"
            })
          }
        }).finally(() => {
          this.$emit('search');
        })
      } else {
        this.$message({
          type: "error",
          message: "请选择要发送的舱单！"
        })
      }
    },
    //删除舱单
    toDelete() {
      if (this.multipleSelection.length > 0) {
        const deleteData = [];
        this.multipleSelection.forEach((item) => {
          deleteData.push(item);
        })
        this.deleteDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.DeleteManifestDialog.init(deleteData);
        })
      } else {
        this.$message({
          type: "error",
          message: "请选择要删除的舱单！"
        })
      }
    },
  },
}
</script>


<style scoped lang="stylus">
.input_div {
  margin: 1rem 0;
  display: flex;
  border-radius: 4px;
  justify-content: space-between;
  background: var(--GRAY-f1f3f6);

  .inline-input {
    margin-right: 1rem;
  }
}

.el_select {
  margin-right: 1rem;
  min-width: 12rem;
  display: block;
}
</style>
